import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
Vue.use(VueResource)

import GitHubAPI from 'vue-github-api'
Vue.use(GitHubAPI, { token: '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' })

// console.log(Vue.GitHubAPI.get('/projects', {}, [this.myGitHubData, 'projects']));

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import './assets/app.scss'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
