<template>
  <div id="app" class="py-4 relative" style="min-height: 100vh">
    <BackDecal/>
    <b-container fluid="md" class="pb-3 px-2">
        <h1 class="display-1 pb-0 mb-1 font-weight-bold text-glitch" :data-text="this.lang.title">{{ this.lang.title }}</h1>
        <div class="display-5 text-glitch-auto mb-2" data-text="this.getLocation()" :style="{'--animate-delay': Math.random()*20000 + 'ms',}">
          <span>{{ this.lang.location }}</span>
        </div>
    </b-container>
    <b-container fluid="md" class="pb-3 px-2">
      <b-row>
        <b-col lg="5">
          <ProfileVue/>
        </b-col>
        <b-col lg="6" offset-lg="1">
          <TecnologiesVue/>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <ExperienceVue/>
    </b-container>
    <!--    <projects-vue/>-->
    <!--  <FooterVue/>-->
  </div>
</template>

<script>
import Vue from 'vue'
import BackDecal from './components/backDecal.vue';
import ProfileVue from './components/profileVue.vue';
import TecnologiesVue from './components/tecnologiesVue.vue';
import ExperienceVue from './components/experienceVue.vue';
// import ProjectsVue from './components/projectsVue.vue';
// import FooterVue from './components/footerVue.vue';
import VueYandexMetrika from 'vue-yandex-metrika';

Vue.use(VueYandexMetrika, {
    id: 92512643,
    env: process.env.NODE_ENV
    // other options
})

export default {
  name: 'App',
  components: {
    BackDecal,
    ProfileVue,
    TecnologiesVue,
    ExperienceVue,
    // ProjectsVue,
    // FooterVue
  },
  data() {
    return {
      lang: {
        title: "Nikolay Filippov",
        location: "Kaliningrad, Russia",
      },
    }
  },

  computed: {

  },

  mounted() {
    const insertScript = document.createElement('script')
    insertScript.setAttribute('src', 'https://cdn.lordicon.com/xdjxvujz.js')
    document.body.appendChild(insertScript)
  }
}
</script>
