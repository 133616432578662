<template>
  <div class="mb-4">
    <h2 class="display-2 font-weight-bold text-glitch-auto mb-2"
        data-text="technologiesOfUse()"
        :style="{'--animate-delay': Math.random()*60000 + 'ms',}"
        ><span>technologies of use</span></h2>
   
    <div class="d-flex flex-column gap-2 mb-1">
      <span class="lead m-0 mr-3 mt-2 text-glitch-auto"
            data-text="this.technologiesOfUse.getTechnologies()" 
            :style="{'--animate-delay': Math.random()*60000 + 'ms',}"
            ><span>technologies</span></span>
      
      <div class="d-flex flex-wrap align-items-center gap-2">
        <iconVue 
          v-for="(i, index) in items"
          :id="'icon:' + index"
          :key="index"
          v-bind:size="32"
          v-bind:name="i.name"
          v-bind:icon="i.icon"
        ></iconVue>
      </div>
    </div>

    <div class="d-flex flex-column gap-2 mb-1" :style="{
      '--animate-delay': Math.random()*60000 + 'ms',
      '--animate-duration': Math.random()*16 + 's',
      }">
      <span class="lead m-0 mr-3 mt-2 text-glitch-auto" 
            data-text="this.technologiesOfUse.getCMS()"
            :style="{'--animate-delay': Math.random()*60000 + 'ms',}"
            ><span>CMS</span></span>
      <div class="d-flex flex-wrap align-items-center gap-2">
        <iconVue 
          v-for="(i, index) in cms"
          :id="'icon:' + index"
          :key="index"
          v-bind:size="32"
          v-bind:name="i.name"
          v-bind:icon="i.icon"
        ></iconVue>
      </div>
    </div>

    <div class="d-flex flex-column gap-2 mb-1" :style="{
      '--animate-delay': Math.random()*60000 + 'ms',
      '--animate-duration': Math.random()*16 + 's',
      }">
      <span class="lead m-0 mr-3 mt-2 text-glitch" 
            data-text="this.technologiesOfUse.getFrameworks()" 
            :style="{'--animate-delay': Math.random()*60000 + 'ms',}"
            ><span>frameworks</span></span>
      <div class="d-flex flex-wrap align-items-center gap-2">
        <iconVue 
          v-for="(i, index) in libraries"
          :id="'icon:' + index"
          :key="index"
          v-bind:size="32"
          v-bind:name="i.name"
          v-bind:icon="i.icon"
        ></iconVue>
      </div>
    </div>

    <div class="d-flex flex-column gap-2 mb-1" :style="{
      '--animate-delay': Math.random()*10000 + 'ms',
      '--animate-duration': Math.random()*16 + 's',
      }">
      <span class="lead m-0 mr-3 mt-2 text-glitch" data-text="tools">tools</span>
      <div class="d-flex flex-wrap align-items-center gap-2">
         <iconVue 
          v-for="(i, index) in tools"
          :id="'icon:' + index"
          :key="index"
          v-bind:size="32"
          v-bind:name="i.name"
          v-bind:icon="i.icon"
        ></iconVue>
      </div>
    </div>
  </div>
</template>

<script>
import IconVue from './icon.vue'

export default {
  components: {
    IconVue
  },
  name: "tecnologiesVue",
  data() {
    return {
      items: [
        {
          name: "HTML5",
          icon: "html5",
        },
        {
          name: "CSS3",
          icon: "css3",
        },
        {
          name: "JavaScript",
          icon: "js",
        },
        {
          name: "TypeScript",
          icon: "ts",
        },
        {
          name: "PHP",
          icon: "php",
        },
      ],
  
      cms: [
        {
          name: "1C-Bitrix",
          icon: "bitrix",
        },
        {
          name: "MediaWiki",
          icon: "mediawiki",
        },
        {
          name: "WordPress",
          icon: "wordpress",
        }
      ],

      tools: [
        {
          name: "Figma",
          icon: "figma",
        },
        {
          name: "SketchApp",
          icon: "sketch",
        },
        {
          name: "Photoshop",
          icon: "ps",
        },
        {
          name: "PHPStorm",
          icon: "jb",
        },
        {
          name: "Grunt",
          icon: "grunt",
        },
        {
          name: "Gulp",
          icon: "gulp",
        },
        {
          name: "Git",
          icon: "git",
        },
        {
          name: "Webpack",
          icon: "webpack",
        }
      ],

      libraries: [
       {
          name: "BX",
          icon: "bitrix",
        },
        {
          name: "jQuery",
          icon: "jquery",
        },
        {
          name: "Bootstrap",
          icon: "bootstrap",
        },
        {
          name: "Tailwind CSS",
          icon: "tailwind",
        },
        {
          name: "VueJS",
          icon: "vue",
        },
        {
          name: "SASS",
          icon: "sass",
        }
      ]
    };
  },

  props: ['randomTimer'],

  methods: {
    colorful(id) {
      document.getElementById(id).classList.remove("colored");
    },
    colorless(id) {
      document.getElementById(id).classList.add("colored");
    },
  },
};
</script>

<style scoped>
._icons {
  font-size: 36px;
  transition: 500ms;
}
._icons:hover {
  /*font-size: 60px;*/
  transition: 230ms;
}
</style>
