<template>
  <div>
    <h2 class="display-2 font-weight-bold text-glitch-auto mb-2" data-text="const experience = ["  style="--animate-delay: 0.3s;"><span>experience</span></h2>
    <div class="d-flex flex-column mb-1 ps-3">
      <div class="" v-for="(itm, index) in items" :key="index" >
        <div class="text-glitch-auto" :style="{'--animate-delay': Math.random()*60000 + 'ms'}" data-text="{"><span>&#183;</span></div>
        <div class="ps-3">
          company: 
          <a v-if="itm.link" target="_blank" class="d-inline display-4 text-white" :href="itm.link">
            <span class="text-glitch d-inline" :data-text="itm.name">{{itm.name}}</span>
          </a>
          <span v-else class="d-inline display-4 text-white">
            <span class="text-glitch d-inline" :data-text="itm.name">{{itm.name}}</span>
          </span>, <br>
          position: <span class="text-glitch d-inline" :data-text="itm.position">{{itm.position}}</span>, <br>
          period: <span class="text-glitch d-inline" :data-text="itm.date">{{itm.date}}</span>
        </div>
        <div class="text-glitch-auto" :style="{'--animate-delay': Math.random()*60000 + 'ms'}" data-text="},"><span>&#183;</span></div>   
      </div>
    </div>
    <div class="text-glitch-auto" :style="{'--animate-delay': Math.random()*60000 + 'ms'}" data-text="]"><span>&#183;</span></div>
    


  </div>
</template>

<script>
export default {
  name: 'experienceVue',
  data () {
    return {
      // myGitHubData: {}
       items: [
        {
          name: "1C-Bitrix",
          date: "sep 2012 - feb 2022",
          position: "Front-end Developer",
          link : "https://1c-bitrix.ru"
        },
        {
          name: "Smart.EXE R",
          date: "oct 2011 - aug 2012",
          position: "Front-end Developer",
          link : null,
        },
        {
          name: "MediaMaxx",
          date: "oct 2010 - jun 2011",
          position: "Front-end Developer // Team Leader",
          link : null,
        },
      ]
    }
  },
  mounted: function () {
    // this.GitHubAPI.get('/user/repos', {}, [this.myGitHubData, 'repositories'])
  },
  computed: {
    // stat: function () {
    //   if (this.myGitHubData.repositories) {
    //     console.log(this.myGitHubData);
    //     return this.myGitHubData.repositories.length
    //   }
    //   return 'none yet...'
    // },
    // repositoriesCount: function () {
    //   if (this.myGitHubData.repositories) {
    //     console.log(this.myGitHubData);
    //     return this.myGitHubData.repositories.length
    //   }
    //   return 'none yet...'
    // }
  }
}
</script>
