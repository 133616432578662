<template>
  <div class="perspective mb-3">
    <p  class="lead mb-3 font-italic" 
        :style="{maxWidth: 640 + 'px', width: '100%'}"
        :data-text="this.lang.lead">{{ this.lang.lead }}</p>
    <div class="text-glitch-auto" :style="{'--animate-delay': Math.random()*60000 + 'ms'}" data-text="const contacts = ["><span>&#183;</span></div>
      <div class="ps-3" v-for="(c, index) in links" :key="index" >
        <a :href="c.link" target="_blank" class="text-white flex align-center">
          <span class="fs-5 text-glitch d-inline-flex" :data-text="c.name">{{c.name}}</span>
        </a>
      </div>
    <div class="text-glitch-auto" :style="{'--animate-delay': Math.random()*60000 + 'ms'}" data-text="]"><span>&#183;</span></div>
  </div>
</template>

<script>
export default {
  name: 'profileVue',
  data() {
    return {
      lang: {
        title: "Nikolay Filippov",
        lead: "I am a person who believes in the power of teamwork, in growing and learning more every day, competitive and who does not give up on her ambitions.",
      },
      links: [
        {
          icon: "github",
          name: "GitHub: SoljDev",
          link : "https://github.com/Soljdev"
        },
        {
          icon: "instagram",
          name: "Instagram: SoljDev",
          link : "https://instagram.com/Soljdev"
        },
        {
          icon: "telegram",
          name: "Telegram: iSolj",
          link : "https://t.me/isolj"
        },
        {
          icon: "email",
          name: "mail@solj.ru",
          link: "mailto:mail@solj.ru"
        },
      ]
    }
  }
}
</script>