var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('h2',{staticClass:"display-2 font-weight-bold text-glitch-auto mb-2",style:({'--animate-delay': Math.random()*60000 + 'ms',}),attrs:{"data-text":"technologiesOfUse()"}},[_c('span',[_vm._v("technologies of use")])]),_c('div',{staticClass:"d-flex flex-column gap-2 mb-1"},[_c('span',{staticClass:"lead m-0 mr-3 mt-2 text-glitch-auto",style:({'--animate-delay': Math.random()*60000 + 'ms',}),attrs:{"data-text":"this.technologiesOfUse.getTechnologies()"}},[_c('span',[_vm._v("technologies")])]),_c('div',{staticClass:"d-flex flex-wrap align-items-center gap-2"},_vm._l((_vm.items),function(i,index){return _c('iconVue',{key:index,attrs:{"id":'icon:' + index,"size":32,"name":i.name,"icon":i.icon}})}),1)]),_c('div',{staticClass:"d-flex flex-column gap-2 mb-1",style:({
    '--animate-delay': Math.random()*60000 + 'ms',
    '--animate-duration': Math.random()*16 + 's',
    })},[_c('span',{staticClass:"lead m-0 mr-3 mt-2 text-glitch-auto",style:({'--animate-delay': Math.random()*60000 + 'ms',}),attrs:{"data-text":"this.technologiesOfUse.getCMS()"}},[_c('span',[_vm._v("CMS")])]),_c('div',{staticClass:"d-flex flex-wrap align-items-center gap-2"},_vm._l((_vm.cms),function(i,index){return _c('iconVue',{key:index,attrs:{"id":'icon:' + index,"size":32,"name":i.name,"icon":i.icon}})}),1)]),_c('div',{staticClass:"d-flex flex-column gap-2 mb-1",style:({
    '--animate-delay': Math.random()*60000 + 'ms',
    '--animate-duration': Math.random()*16 + 's',
    })},[_c('span',{staticClass:"lead m-0 mr-3 mt-2 text-glitch",style:({'--animate-delay': Math.random()*60000 + 'ms',}),attrs:{"data-text":"this.technologiesOfUse.getFrameworks()"}},[_c('span',[_vm._v("frameworks")])]),_c('div',{staticClass:"d-flex flex-wrap align-items-center gap-2"},_vm._l((_vm.libraries),function(i,index){return _c('iconVue',{key:index,attrs:{"id":'icon:' + index,"size":32,"name":i.name,"icon":i.icon}})}),1)]),_c('div',{staticClass:"d-flex flex-column gap-2 mb-1",style:({
    '--animate-delay': Math.random()*10000 + 'ms',
    '--animate-duration': Math.random()*16 + 's',
    })},[_c('span',{staticClass:"lead m-0 mr-3 mt-2 text-glitch",attrs:{"data-text":"tools"}},[_vm._v("tools")]),_c('div',{staticClass:"d-flex flex-wrap align-items-center gap-2"},_vm._l((_vm.tools),function(i,index){return _c('iconVue',{key:index,attrs:{"id":'icon:' + index,"size":32,"name":i.name,"icon":i.icon}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }