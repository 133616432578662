<template>
  <div class="icon" :style="{ 
      backgroundImage: 'url(' + itemImage + ')', 
      width: this.size + 'px'
    }">
    <div class="icon--glitch" :style="{ backgroundImage: 'url(' + itemImage + ')', width: this.size + 'px' }"></div>
    <!-- {{ name }} -->
    <span>{{ icon }}</span>
  </div>
</template>

<script>
export default {
  name: 'iconVue',
  props: {
    icon: String,
    name: String,
    size: Number,
  },
  data () {
    return {
      // myGitHubData: {}
    }
  },
  mounted: function () {
    // this.GitHubAPI.get('/user/repos', {}, [this.myGitHubData, 'repositories'])
  },
  computed: {
   itemImage() {
      const fileName = this.icon.toLowerCase();
      return require(`../assets/img/${fileName}.svg`);
    }
  }
}
</script>

<style>
.icon,
.icon--glitch  {
  display: block;
  position: relative;
  background: no-repeat center;
  background-size: contain;
}

.icon::after {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}

.icon span {display: none;}


.icon {
  display: block;
  /* opacity: .5; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* position: absolute; */
}

.icon--glitch {
  display: none;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: -1px  1px 0 0 blue, 
               1px  1px 0 0 red, 
               1px -1px 0 0 #0f0;
  filter: grayscale(0%) 
          /* brightness(100%)  */
          /* saturate(0%)  */
          /* contrast(100%) */
          /* invert(86%)  */
          drop-shadow(-6px 6px blue) 
          drop-shadow(6px 6px red) 
          drop-shadow(6px 6px green);
}



</style>