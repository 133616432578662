<template>
  <div class="decal-box">
    <div class="mendel">
      <div class="mendel--glitch"></div>
    </div>
  </div>
</template>

<script>
import '../assets/backDecal.scss'

export default {
  name: "backDecal",
    data() {
      return {
      }
    }
};
</script>
